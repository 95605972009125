.filter_boxGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 24px;
}

.filterFx_basis {
    flex: 0 0 calc(33.33% - 16px);
}

.cstFrm_inputs {
    width: 100%;
    padding: 6px;
    border: 1px solid #ced4da;
    background: #fff;
    border-radius: 3px;
    min-height: 36px;
}

.cstFrm_inputs:focus {
    border-color: var(--body-color-secondary);
}

.cstFrm_inputs:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.cstFrm_inputs {
    position: relative;
}

.cstFrm_inputs[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.mainDate_picBx {
    position: relative;
}

.dateSvg {
    position: absolute;
    top: 9px;
    right: 9px;
}

.getHistoryBtn {
    width: 100%;
    background-color: var(--body-color-secondary);
    color: #fff;
    border: 0;
    padding: 8px;
    font-weight: 600;
    transition: all .2s;
}

.getHistoryBtn:hover {
    background-color: #4a4189 !important;
}

.accStat_contain .filter_box.filter_boxGrid {
    padding: 10px;
    margin-top: 10px;
}

@media (max-width: 479px) {
    .d-flxe.gap-1.filterFx_basis.filterFx_basis_full {
        flex: 0 0 100%;
    }
}

@media (max-width: 767px) {
    .filter_boxGrid {
        gap: 14px 14px;
    }

    .filterFx_basis {
        flex: 0 0 calc(50% - 7px);
    }
}