:root {
  --body-color-primary: #47388c;
  --body-color-secondary: #9a93e0;
}

body {
  /* padding-top: 70px; */
  background-color: rgb(233, 233, 233);
  font-family: "Open Sans", sans-serif !important;
  margin: 0;
}

@media all and (max-height: 480px) {
  body {
    /* padding-top: 70px; */
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}
.align-items-baseline{
  align-items: baseline
}
.w-100 {
  width: 100% !important;
}

.pointer {
  cursor: pointer;
}

/*@media all and (max-width: 640px){
  body {
    padding-top: 25%;
  }
}*/
.navbar-header {
  margin-top: 5px;
}

.pro-img-tag {
  padding-top: 5px;
}

pre {
  tab-size: 8;
}

.header-menue {
  margin: 0px 0px !important;
}

.main_menu_btn {
  text-align: center;
  margin: 0px auto !important;
}

td.clear-button {
  background-image: url("/src/assets/images/delete.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px auto;
}

.font-sans-serif {
  font-family: sans-serif !important;
}

.font-size-40 {
  font-size: 30px;
  font-weight: 800;
}

.fw-80 {
  font-weight: 800;
}

.ls-2 {
  letter-spacing: 2px;
}

@media screen and (max-width: 767px) {
  .side-collapse {
    top: 60px;
    bottom: 0;
    right: 0;
    width: 300px;
    position: fixed;
    overflow: hidden;
    transition: width 0.4s;
    z-index: 1000;
  }

  .side-collapse.in {
    width: 0;
  }

  .navbar-inverse .navbar-collapse {
    border-color: #3a61a2 !important;
  }

  .pro-img-tag {
    padding-top: 0px;
  }

  .font-size-40 {
    font-size: 20px;
    font-weight: 800;
  }
}

.img-circle {
  background-color: none !important;
}

@media all and (max-width: 767px) {
  .refresh_icon_menu_header {
    position: absolute;
    left: 85%;
    top: 12px;
    display: inline-block !important;
  }

  .navbar-toggle .icon-bar {
    height: 5px !important;
  }
}

@media all and (min-width: 400px) and (max-width: 550px) {
  .refresh_icon_menu_header {
    left: 80% !important;
  }
}

@media all and (max-width: 399px) {
  .refresh_icon_menu_header {
    left: 75% !important;
  }
}

.refresh_icon_menu_header {
  display: none;
}

.marquee {
  width: 100%;
  overflow: hidden;
  border: 0px solid #ccc;
  background: #6e8fc9;
  color: #fff;
  padding: 0px 1px;
  margin-right: 15%;
  margin-bottom: 13px;
}

ul.marquee li {
  display: inline-block;
  padding: 5px 10px 1px;
  font-size: 16px;
  font-weight: 600;
  font-family: "open-sans-regular" !important;
}

.lang-switch-btn {
  position: relative !important;
  top: 0px !important;
  margin-bottom: 25px !important;
}

.rules-main-container {
  top: 170px !important;
}

.inplay-match {
  position: relative;
}

.inplay-match>.active {
  width: 12px;
  height: 12px;
  background: #7dd834;
  border-radius: 90%;
  position: absolute;
  top: 5px;
  right: 5px;
}

.session_rate {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  display: none;
}

.session_bhav {
  line-height: 1.2;
  margin: 0;
  padding: 0px;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  font-weight: 700 !important;
}

.video_container {
  width: 99% !important;
  margin-left: 2px;
  top: 60px;
  /* background: #ffffff !important; */
  z-index: 1;
  transition: "opacity 0.3s ease-in-out, visibility 0s linear 0.3s";
}

@media (max-width: 768px) {
  .chng-pass-container {
    top: 115px;
  }

  .container.dashboard_content {
    margin-top: 25px;
  }

  .append_video {
    position: fixed !important;
    z-index: 2;
  }

  .session_rate {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    display: block;
    color: #2c2828;
  }

  #session_bet_row {
    display: none;
  }

  .session_bhav {
    font-size: 16px;
  }
}

/* Match Status CSS Start */
.alert-match-status-box {
  padding: 10px;
}

.alert-match-status-text {
  padding: 15px 15px;
  height: 80px;
  text-align: center;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  color: #a94442;
  border-radius: 7px;
  display: table;
}

.alert-match-status-text span {
  font-size: 18px;
  display: table-cell;
  vertical-align: middle;
  font-family: "open-sans-regular" !important;
}

@media all and (max-width: 420px) {
  .alert-match-status-text span {
    font-size: 16px;
  }
}

.srv-validation-message {
  text-align: left;
  font-size: 11px;
  color: #b74545;
}

.loginscreen {
  width: 400px;
  margin: auto;
  background: var(--body-color-primary) !important;
  padding: 28px;
  border-radius: 10px;
  box-shadow: 6px 4px 10px #a7a0eb;
  color: #fff;
}

.captch {
  padding: 0px;
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 6px;
  width: 42%;
  height: 50px;
  text-align: center;
  padding-top: 12px;
  padding-right: 8px;
  position: relative;
  left: 30%;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%) !important;
  border: solid 1px rgba(38, 117, 49, 0.38);
}

/* @media all and (min-width: 260px) and (max-width: 419px) {
    .Game_ui_responsive{
        margin-left: 24%;
        margin-bottom: 10%;
    }
} */

.Game_last_name {
  background-color: black;
  color: white !important;
  font-weight: 900;
  position: absolute;
  top: 180px;
  text-align: center;
  z-index: 999;
  width: 100%;
}

.game-box.Game_ui_responsive a {
  display: block !important;
  position: relative;
}

.cst_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* video modal */

/* .rounded_class{
    background-color: red;
}

.icon_text_color{
    color: yellow;
}


element.style {
}
.dragOuter-toggler.showToggler {
    display: flex;
    animation: jl-horizontal 1s 2s 2 both;
} */

@media all and (min-width: 100px) and (max-width: 767px) {
  .Home_contant {
    /* margin-top: -60px; */
  }
}

@media all and (min-width: 768px) and (max-width: 3000px) {
  .Home_contant {
    /* margin-top: -80px; */
    height: 100vh;
  }
}

.home_background {
  background-image: url("../src/images/home_bg.jpg");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  opacity: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
}

.Home_contant {
  position: relative;
}

.overlay_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 0;
  opacity: 0.3;
}

.home_background .text-center h1 {
  font-size: 48px;
  color: #fff;
}

.txt_divider {
  margin: 25px auto 40px;
  width: 100px;
  height: 2px;
  background: #4fbfa8;
}

.home_background .row {
  display: flex;
  justify-content: center;
}

.btn1 {
  border: 2px solid white !important;
  font-family: inherit;
  font-size: inherit;
  color: white !important;
  background: none;
  cursor: pointer;
  padding: 15px 60px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  position: relative;
  outline: none;
}

.btn1:hover .btn_name {
  color: #3a61a2 !important;
}

button.btn1 .bg_spot {
  content: "";
  background-color: #fff;
  position: absolute;
  height: 52px;
  width: 0%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

span.btn_name {
  z-index: 999;
  position: relative;
}

button.btn1:hover .bg_spot {
  width: 100%;
}

.font_weight {
  font-weight: 600;
  font-family: sans-serif;
}

#refresh2 {
  z-index: 999 !important;
}

.arjun {
  margin-bottom: -4px !important;
}

/* ////////////////////////// lader result card start /////////////////////// */
.result-container {
  background-color: #fff;
  color: #49494a;
  padding: 8px;
  font-family: "Open Sans", sans-serif !important;
  border: 5px solid #e9e9e9 !important;
  text-align: center;
  vertical-align: middle !important;
  margin-bottom: 10px;
}

.round-id {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}

.card-container-box {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.card-container img {
  height: 50px;
  width: 41px;
  margin: 5px 10px;
}

.card-container b {
  font-size: 20px;
  margin-right: 10px;
}

.row1 {
  justify-content: flex-start;
}

.result-text {
  margin-top: 10px;
  background-color: var(--light-bg);
  padding: 10px;
  box-shadow: 0 0 5px #aaa;
}

/* ////////////////////////// lader result card end /////////////////////// */

.score_widget_frame {
  background-color: var(--body-color-primary) !important;
  max-height: inherit;
  min-height: inherit;
  padding-left: 0px;
}

.matka_menu_box {
  background: var(--body-color-primary) !important;
  margin-top: 20px;
  /* width: 100%; */
  color: #fff;
  display: flex;
  padding-top: 10px !important;
  padding-bottom: 10px;
  justify-content: space-between;
  /* margin-right: -15px;
  margin-left: -15px; */
  align-items: center;
}

.matka_menu_box>div {
  /* width: 250px; */
  margin: 0 10px;
}

.matka_menu_item {
  color: var(--body-color-primary) !important;
  margin-right: 20px;
  margin-bottom: 0;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  background-color: #fff;
  font-weight: 600;
  top: 4px px;
  letter-spacing: 2px;
  cursor: pointer;
}

.matka_menu_item.active {
  background-color: var(--body-color-secondary);
  color: #ffffff !important;
  cursor: auto;
}

.bet_btn.clear {
  background-color: #880808 !important;
}

.bet_btn.submit {
  background-color: #1a6706 !important;
  position: relative;
}

.bet_btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.bet_btn {
  color: #ffffff !important;
  box-shadow: none !important;
  font-weight: 600;
  border-radius: 11px;
  justify-content: center;
  border: 0;
  height: 100%;
  padding: 12px;
  width: 100px;
}

.column_value_item input {
  width: 100px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  padding-left: 5px;
}

.tooltip_box {
  cursor: pointer;
}

.tooltip_box:hover .tooltip {
  opacity: 1;
  background-color: var(--body-color-primary) !important;
  color: #ffffff !important;
  padding: 5px 10px;
}

#runs-2-value-box-color td {
  background: #ffa5cb !important;
  border: 1px solid #ffffff !important;
  /* border-bottom: 5px solid #fefefe !important; */
  color: #000000 !important;
}

#runs-1-value-box-color :nth-child(1) {
  border-left: 5px solid #fefefe !important;
}

#runs-1-value-box-color :last-child {
  border-right: 5px solid #fefefe !important;
}

#runs-2-value-box-color :nth-child(1) {
  border-left: 5px solid #fefefe !important;
}

#runs-2-value-box-color :last-child {
  border-right: 5px solid #fefefe !important;
}

#runs-1-value-box-color td {
  background: #9dd7ff !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
}

#runs-bet-value-box-color td {
  background: #9dd7ff !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
}

.bet_cancel_btn {
  color: #ffffff !important;
  box-shadow: none !important;
  font-weight: 600;
  border-radius: 10px;
  justify-content: center;
  border: 0;
  height: 100%;
  padding: 12px;
  width: 100px;
  background-color: #880808 !important;
  position: relative;
}

.bet_cancel_btn:disabled {
  background-color: #a16c6c !important;
  cursor: not-allowed;
}

.bet_list_arrow {
  width: 103px;
  height: 31px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

/* //////////////////// Login ////////////////////// */
/* .containerLogin {
  background: #276aa5;
  background-image: url(/images/dhoni.svg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} */


.screen_content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.login_form {
  width: 100%;
  /* padding: 30px; */
  padding: 12px 12px 0 12px;
  padding-top: 0px;
  /* padding-bottom: 20px; */
  /* display: grid; */
  height: calc(100% - 75.59px);
  align-items: center;
  grid-gap: 20px;
  /* gap: 15px; */
  gap: 5px;
  align-content: flex-end;
}

.login__input {
  border: 2px solid rgb(128 129 136);
  background: white;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 100%;
  height: 50px;
  transition: 0.2s;
}

.login__submit {
  background: #276aa5;
  font-size: 14px;
  margin-top: 10px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  transition: 0.2s;
  outline: none;
}

.login__submit:focus {
  outline: none;
  color: #fff;
  background: #276aa5 !important;
}

.login__submit:hover {
  background: #2f618d !important;
  color: #fff;
}

.login_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.6em;
}


.login_logo img {
  /* width: 150px; */
  width: 120px;
  object-fit: contain;
  /* border-radius: 100%; */
}

.login_logo h4 {
  font-size: 22px;
  font-weight: 600;
  color: #3f368e;
}

.login_privacy {
  width: 100%;
  padding: 0 22px 0;
}

.login_privacy small {
  font-size: 12px;
  font-weight: 700;
}

.login_privacy p {
  text-align: center;
  margin-top: 7px;
  font-weight: bold;
  color: #3f368e;
}

.srv-validation-message {
  color: #ed2732;
  font-weight: 400;
}

.captcha_input_box div:nth-child(1) {
  height: 36px !important;
  /* padding-top: 8px; */
  width: 129px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  /* margin: 0.5em auto 1em; */
  margin: 0 auto 5px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.floating_input_box input.captcha_input {
  height: 36px;
  padding: 10px 10px 10px 15px;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid #d3d3d3;
  font-weight: 700;
  width: 100%;
  outline: none;
  border-radius: 0;
}

.floating_input_box input.captcha_value {
  font-size: 18px;
  letter-spacing: 14px;
}

.srv-validation-message {
  margin-top: 5px;
}

.bet-left-row {
  padding: 0px;
  display: flexjus;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.total_amount_text {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gray;
  height: 67px;
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.chart_link_box {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer !important;
}

.matka_date_picker_cont {
  background: var(--body-color-primary) !important;
}

.matka_date_picker_box {
  padding: 20px 5px;
  display: flex;
  justify-content: end;
}

.date-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.matka_date_picker_box button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.box_title {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-weight: 700;
  border-bottom: 1px solid rgb(147, 147, 147);
  align-items: center;
  padding: 0 12px;
}

.event_link {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.chart_table_box {
  width: 100%;
  overflow: auto;
}

.chart_table_box table {
  min-width: 780px !important
}

.lock_bet_cancle {
  top: 2px;
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: #1414146e;
  padding-top: 5px;
  left: 0;
  cursor: not-allowed;
  border-radius: 10px;
}

.matka_name {
  margin-top: 0;
  font-size: 26px;
  overflow: scroll;
  white-space: nowrap;
  padding-left: 25px;
}

.matka_name::-webkit-scrollbar {
  display: none;
}

.screen {
  background: #ffffff;
  position: relative;
  /* 
  width: 360px; */
  /* min-height: 430px; */
  /* width: 470px; */
  width: 350px;
  /* border-top: 11px solid #D3D3D3; */
  border-radius: 4px;
  box-shadow: 0 0 5px #fff;
}


/* /////////////////////////////////////////// media //////////////////////////////////////////////////////////// */

@media (max-width: 769px) {
  #session_status {
    padding-top: 18px !important;
  }

  .matka_name {
    font-size: 20px;

  }

  .containerLogin {
    background: #276aa5;
    /* background-image: url(/images/dhoni.svg); */
    background: linear-gradient(180deg, #FFFFFF -16.89%, #3F368E 100%);

    background-size: cover;
    /* display: flex; */
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .screen {
    /* background: transparent !important; */
    position: relative;
    /* min-height: 430px; */
    /* width: 470px; */
  }

  .captcha_label {
    color: #ffffff;
  }

  .table.table-bordered .active-lagai-bet-box-color {
    font-size: 16px !important;
    padding-top: 7px !important;
  }

  .table.table-bordered .active-khai-bet-box-color {
    font-size: 16px !important;
    padding-top: 7px !important;
  }

  .session_min_height {
    height: 55px !important;
  }

  .login_inpLabelcls {
    color: rgb(255, 255, 255) !important;
    font-size: 12px;
  }

  .floating_input_box input.captcha_input {
    font-size: 12px;
  }

  .floating_input_box input.captcha_value {
    font-size: 16px;
    letter-spacing: 12px;
  }

  .total_amount_text {
    display: none;
  }

  .total_row_amount {
    display: none;
  }

  .chart_table_box {
    max-width: 800px;
    width: 100%;
    overflow: auto;
  }

  .mainMatka_table .dayHead th {
    font-size: 7px;
    padding: 5px 1px;
  }

  table.mainMatka_table .datesTd {
    padding: 2px !important;
    font-size: 7px;
  }

  table.mainMatka_table .datesTd div {
    width: auto !important;

  }

  table.mainMatka_table .datesTd div br:first-child,
  table.mainMatka_table .datesTd div br:nth-child(3) {
    display: none;
  }

  table.mainMatka_table tbody tr td {
    padding: 5px !important;
    width: auto !important;
  }

  .chrtChar_innrFx {
    font-size: 8px !important;
    /* border: none !important; */
  }

  .chrtChar_bx {
    box-shadow: 0 2px 2px -2px #0e111900 !important;
  }

  .mainMatka_table .dayHead th div {
    width: auto !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .middle-cht {
    font-size: 11px !important;
  }

  .chart_table_box table {
    min-width: auto !important;
    table-layout: fixed;
  }

  table.mainMatka_table tbody tr td {
    line-height: normal !important;
  }
}

@media (min-width: 769px) {
  .containerLogin {
    background: linear-gradient(180deg, #FFFFFF -16.89%, #3F368E 100%);
    /* background-image: none; */
    /* display: flex; */
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .screen {
    background: #ffffff;
    position: relative;
    /* 
    width: 360px; */
    /* min-height: 430px; */
    /* width: 470px; */
    width: 350px;
    /* border-top: 11px solid #D3D3D3; */
    border-radius: 4px;
    box-shadow: 0 0 5px #fff;
  }

}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.lock_team_betting_pt {
  padding: 0 !important;
}

.fancy_session_th {
  white-space: nowrap !important;
  overflow: auto !important;
}

.lockedNum {
  padding-top: 5px;
}

.lock_team_betting_pt .lockedNum {
  opacity: 0;
  min-height: 45px !important;
}

.user-menu {
  position: relative;
  display: inline-block;
}

.user-menu-dropdown {
  position: absolute;
  text-align: left;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
}

.user-menu-dropdown li {
  padding: 8px 12px;
  width: 150px;
  font-weight: 600;
  border-bottom: 1px solid #f8f6f6;
  color: #212529 !important;
}

.user-menu-dropdown li:hover {
  background-color: #007bff;
  border-bottom: 1px solid #f8f6f6;
  cursor: pointer;
}

.user_icon{
  background: #c3bfbf;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.user-menu-dropdown li button {
  background: none;
  border: none;
  color: #212529;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.user-menu:hover .user-menu-dropdown {
  display: block;
}

/* Responsive */
@media (max-width: 359px) {
  .table.table-bordered th.bet-place-tbl-th {
    font-size: 10px;
    padding: 5px;
  }

  .table.anvSesstion_table th div {
    font-size: 10px !important;
  }
}

@media (min-width: 360px) and (max-width: 400px) {
  .table.table-bordered th.bet-place-tbl-th {
    font-size: 12px;
    padding: 6px;
  }
}

.bhav_line_height {
  min-height: 40px !important;
}

#session_status {
  width: 100%;
  text-align: center;
  color: red;
  background: #ECECEC !important;
  border: 1px solid #FFFFFF !important;
  font-weight: 700;
  padding-top: 10px;
  font-size: 16px;
}

.blink {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

.ReactModal__Overlay--after-open {
  z-index: 100;
}

/* //////////////////// Login ////////////////////// */

/* ////////////////////////////////////////////// */
.st0 {
  fill: #010101;
}

.st1 {
  fill: #ee6b6b;
}

.st2 {
  fill: #fdb94b;
}

.st3 {
  fill: #020202;
}

.st4 {
  fill: #010201;
}

.st5 {
  fill: #fcb94b;
}

.st6 {
  fill: #59506a;
}

.st7 {
  fill: #5a506b;
}

.st8 {
  fill: #594f6a;
}

.st9 {
  fill: #020201;
}

.st10 {
  fill: #050401;
}

.st11 {
  fill: #030304;
}

.st12 {
  fill: #060506;
}

.st13 {
  fill: #fec353;
}

.st14 {
  fill: #ffd166;
}

.st15 {
  fill: #030301;
}

.st16 {
  fill: #030201;
}

.st17 {
  fill: #040301;
}

.st18 {
  fill: #ffffff;
}

.st19 {
  fill: #fff6d9;
}

.st20 {
  fill: #64326d;
}

.st21 {
  fill: #e58e66;
}

.st22 {
  fill: #e2d674;
}

.st23 {
  fill: #f8ec7d;
}

.st24 {
  fill: #83d4f0;
}

.st25 {
  fill: #83d3ef;
}

.st26 {
  fill: #81ceeb;
}

.st27 {
  fill: #d7e486;
}

.st28 {
  fill: #fbbf9f;
}

.st29 {
  fill: #85afdd;
}

.st30 {
  fill: #e2eaa2;
}

.st31 {
  fill: #040403;
}

.st32 {
  fill: #1b65b1;
}

.st33 {
  fill: #729bd1;
}

.st34 {
  fill: #080808;
}

.st35 {
  fill: #dbe9f7;
}

.st36 {
  fill: #fee646;
}

.st37 {
  fill: #fdb838;
}

.st38 {
  fill: #aec2d3;
}

.st39 {
  fill: #f5d60e;
}

.st40 {
  fill: #bed0e0;
}

.st41 {
  fill: #f5d60f;
}

.st42 {
  fill: #f4d610;
}

.st43 {
  fill: #8eca79;
}

.st44 {
  fill: #79c260;
}

.st45 {
  fill: #3754a5;
}

.st46 {
  fill: #4461ad;
}

.st47 {
  fill: #75a2d6;
}

.st48 {
  fill: #6391cc;
}

.st49 {
  fill: #080908;
}

.st50 {
  fill: #ffcf86;
}

.st51 {
  fill: #f3eae5;
}

.st52 {
  fill: #fee178;
}

.st53 {
  fill: #fddcd4;
}

.st54 {
  fill: #f27b96;
}

.st55 {
  fill: #a3d49f;
  stroke: #000000;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st56 {
  fill: #ebf5ed;
}

.st57 {
  fill: #68c39c;
}

.st58 {
  fill: #385e7e;
}

.st59 {
  fill: #e8834d;
}

.st60 {
  fill: #417194;
}

.st61 {
  fill: #f9cf59;
}

.st62 {
  fill: #dae6f1;
}

.st63 {
  fill: #754f35;
}

.st64 {
  fill: #f8d0b5;
}

.st65 {
  fill: #f1667e;
}

.st66 {
  fill: #eef3fa;
}

.st67 {
  fill: #ee4561;
}

.st68 {
  fill: #fefefe;
}

.st69 {
  fill: #fcfcfc;
}

.st70 {
  fill: #d2a285;
}

.st71 {
  fill: none;
}

.st72 {
  fill: #3d3e3f;
}

.st73 {
  fill: #5bcbf5;
}

.st74 {
  fill: #2d2d2d;
}

.st75 {
  fill: #dd4a56;
}

.st76 {
  fill: #808b95;
}

.st77 {
  fill: #909ba5;
}

.st78 {
  fill: #f6e07f;
}

.st79 {
  fill: #8f9aa4;
}

.st80 {
  fill: #d8313c;
}

.st81 {
  fill: #eccd61;
}

.st82 {
  fill: #d8323d;
}

.st83 {
  fill: #f5df7f;
}

.st84 {
  fill: #dd4a55;
}

.st85 {
  fill: #ebcc62;
}

.st86 {
  fill: #616d76;
}

.st87 {
  fill: #5f6c76;
}

.st88 {
  fill: #444d55;
}

.st89 {
  fill: #93d3c3;
}

.st90 {
  fill: #4273b8;
}

.st91 {
  fill: #606161;
}

.st92 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 9;
  stroke-miterlimit: 10;
}

.st93 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 10;
  stroke-miterlimit: 10;
}

.st94 {
  fill: #514e4e;
}

.st95 {
  fill: #010202;
}

.st96 {
  fill: #575656;
}

.st97 {
  fill: #99d1a0;
}

.st98 {
  fill: #5097d1;
}

.st99 {
  fill: #72c8f1;
}

.st100 {
  fill: #11111b;
}

.st101 {
  fill: #111420;
}

.st102 {
  fill: #10131e;
}

.st103 {
  fill: #1e1e27;
}

.st104 {
  fill: #1b1b24;
}

.st105 {
  fill: #191922;
}

.st106 {
  fill: #10101a;
}

.st107 {
  fill: #282831;
}

.st108 {
  fill: #2a2a34;
}

.st109 {
  fill: #191a23;
}

.st110 {
  fill: #181922;
}

.st111 {
  fill: #14151e;
}

.st112 {
  fill: #181821;
}

.st113 {
  fill: #161720;
}

.st114 {
  fill: #f1f0f1;
}

.st115 {
  fill: #f4f4f5;
}

.st116 {
  fill: #f2f2f2;
}

.st117 {
  fill: #f8f8f8;
}

.st118 {
  fill: #f3f2f3;
}

.st119 {
  fill: #efeeef;
}

.st120 {
  fill: #e2e1e3;
}

.st121 {
  fill: #f6f5f5;
}

.st122 {
  fill: #edebec;
}

.st123 {
  fill: #c1c0c4;
}

.st124 {
  fill: #fcfcfd;
}

.st125 {
  fill: #f9f9f9;
}

.st126 {
  fill: #fbfbfb;
}

.st127 {
  fill: #f3f2f2;
}

.st128 {
  fill: #12131e;
}

.st129 {
  fill: #2a2a32;
}

.st130 {
  fill: #edeced;
}

.st131 {
  fill: #edecec;
}

.st132 {
  fill: #21212a;
}

.st133 {
  fill: #feffff;
}

.st134 {
  fill: #faf9fa;
}

.st135 {
  fill: #1c1c25;
}

.st136 {
  fill: #10111a;
}

.st137 {
  fill: #fdfefe;
}

.st138 {
  fill: #fefeff;
}

.st139 {
  fill: #11111c;
}

.st140 {
  fill: #73c9f1;
}

.st141 {
  fill: #fdfdfd;
}

.st142 {
  fill: #73c8f1;
}

.st143 {
  fill: #74c9f1;
}

.st144 {
  fill: #141621;
}

.st145 {
  fill: #151620;
}

.st146 {
  fill: #fafdfe;
}

.st147 {
  fill: #161620;
}

.st148 {
  fill: #1b1c24;
}

.st149 {
  fill: #1a1a23;
}

.st150 {
  fill: #231f20;
}

.st151 {
  fill: #5d5a5a;
}

.st152 {
  fill: #49c2c6;
}

.st153 {
  fill: #7ac9c9;
}

.st154 {
  fill: #7ac9ca;
}

.st155 {
  fill: #4ac2c6;
}

.st156 {
  fill: #fffefe;
}

.st157 {
  fill: #10111b;
}

.st158 {
  fill: #10111c;
}

.st159 {
  fill: #10131d;
}

.st160 {
  fill: #11131e;
}

.st161 {
  fill: #14141e;
}

.st162 {
  fill: #12131d;
}

.st163 {
  fill: #15161f;
}

.st164 {
  fill: #13141d;
}

.st165 {
  fill: #2c2c33;
}

.st166 {
  fill: #323239;
}

.st167 {
  fill: #23232b;
}

.st168 {
  fill: #202029;
}

.st169 {
  fill: #1c1d26;
}

.st170 {
  fill: #292930;
}

.st171 {
  fill: #1f1f28;
}

.st172 {
  fill: #24242c;
}

.st173 {
  fill: #171821;
}

.st174 {
  fill: #1f1f29;
}

.st175 {
  fill: #121926;
}

.st176 {
  fill: #192a3b;
}

.st177 {
  fill: #fafafa;
}

.st178 {
  fill: #f7f7f7;
}

.st179 {
  fill: #030303;
}

.st180 {
  fill: #020302;
}

.st181 {
  fill: #040404;
}

.st182 {
  fill: #050505;
}

.st183 {
  fill: #0e0e0e;
}

.st184 {
  fill: #1d1d1d;
}

.st185 {
  fill: #1a1a1a;
}

.st186 {
  fill: #1f2020;
}

.st187 {
  fill: #555554;
}

.st188 {
  fill: #fffffe;
}

.st189 {
  fill: #f1f0f0;
}

.st190 {
  fill: #090909;
}

.st191 {
  fill: #fdfdfc;
}

.st192 {
  fill: #cd2227;
}

.abc {
  height: 100vh !important;
  width: 100% !important;
}

.maintenanceStyle .img-mainten {
  width: 100% !important;
  height: 100vh;
  mix-blend-mode: multiply;
}