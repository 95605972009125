.stick_box {
  font-weight: bold;
  font-family: "Arial", "Helvetica", "Helvetica Neue";
  color: #2f2f2f;
  font-size: 28px;
  cursor: pointer;
}

.runner_value_box {
  grid-template-rows: repeat(10, 1fr);
  grid-auto-flow: column !important;
  display: grid;
  grid-row-gap: 2px;
  grid-column-gap: 15px;
  flex-wrap: wrap;
}

.runner_value_singlepatti {
  grid-template-rows: repeat(13, 1fr);
}

.runner_value_Singleakda {
  grid-template-rows: repeat(1, 1fr);
}

.runner_value_jodi {
  grid-template-rows: repeat(11, auto);
}

.runner_value_item {
  font-size: 30px;
  text-align: center;
  display: flex;
  flex: 0 0 calc(10% - 13.5px);
}

.all_bazar_bx {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}

.separator_box_line {
  display: none;
}

input.matka_top_input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 55px;
  font-size: 20px;
  font-weight: 700;
  /* text-align: center; */
}

.matka_top_input_box{
  width: 100%;
}
input.matka_total_box {
  font-size: 20px;
}

@media (min-width: 300px) and (max-width: 359px) {
    .runner_value_box {
        display: flex !important;
        grid-column-gap: 15px;
        flex-wrap: wrap;
      }
    .jodi_box {
        flex-basis: calc(33.33% - 10px) !important;
      }
      .runner_value_box {
        grid-column-gap: 7px !important;
      }

      p.matka_menu_item {
        font-size: 12px;
        padding: 6px 5px 6px 5px;
        margin-right: 0px;
      }
    
      .matka_menu_box > div {
        flex-wrap: wrap;
        gap: 15px;
      }
    
      .matka_menu_box.row.content-top-padding {
        padding-top: 10px !important;
      }
    
      .runner_value_item {
        flex: 0 0 calc(24.33% - 10px);
      }
      .matka_input_box input {
        padding: 20px 4px 10px 5px !important;
        height: 43px;
        font-size: 14px;
      }
      .matka_input_box label {
        top: -8px;
        left: 5px;
      }
      .bet_btn {
        padding: 10px 0px;
        width: 70px;
      }
      .column_value_item input {
        width: 70px;
        height: 40px;
        font-size: 15px;
        padding: 1px;
      }
}

@media (min-width: 360px) and (max-width: 479px) {
    .runner_value_box {
        display: flex !important;
        grid-column-gap: 15px;
        flex-wrap: wrap;
      }
    .runner_value_box {
        grid-column-gap: 10px !important;
      }
      .jodi_box {
        flex-basis: calc(33.33% - 10px) !important;    
      }
      p.matka_menu_item {
        font-size: 12px;
        padding: 6px 5px 6px 5px;
        margin-right: 0px;
      }
    
      .matka_menu_box > div {
        flex-wrap: wrap;
        gap: 15px;
      }
    
      .matka_menu_box.row.content-top-padding {
        padding-top: 10px !important;
      }
    
      .runner_value_item {
        flex: 0 0 calc(24.33% - 10px);
      }
      .matka_input_box input {
        padding: 20px 4px 10px 5px !important;
        height: 43px;
        font-size: 14px;
      }
      .matka_input_box label {
        top: -8px;
        left: 5px;
      }
      .bet_btn {
        padding: 10px 0px;
        width: 70px;
      }
      .column_value_item input {
        width: 70px;
        height: 40px;
        font-size: 15px;
        padding: 1px;
      }
}

@media (min-width: 200px) and (max-width: 767px) {
    .separator_box_line {
        display: block;
        flex-basis: calc(100% - 5px);
        height: 1px;
        background: #a7a6a9;
        margin-bottom: 15px;
      }
      .runner_value_box {
        display: flex !important;
        grid-column-gap: 15px;
        flex-wrap: wrap;
      }
      .runner_value_singlepatti.runner_value_box {
        grid-column-gap: 15px !important;
    }
    }
@media (min-width: 480px) and (max-width: 767px) {
      .jodi_box {
        display: flex !important;
        grid-column-gap: 0px;
        flex-wrap: wrap;
        flex-basis: calc(33.33% - 10px);
      }
      .runner_value_item span {
        font-size: 14px;
      }
    
      .tabOver_flowBx {
        width: 740px;
        max-height: 300px;
      }
    
      td.tabOver_lapBx {
        overflow: auto;
      }
    
      .runner_value_item {
        font-size: 16px;
      }
    
      p.matka_menu_item {
        font-size: 12px;
        padding: 6px 5px 6px 5px;
        margin-right: 0px;
      }
    
      .matka_menu_box > div {
        flex-wrap: wrap;
        gap: 15px;
      }
    
      .matka_menu_box.row.content-top-padding {
        padding-top: 10px !important;
      }
}


@media (min-width: 768px) and (max-width: 991px) {
  .runner_value_box {
    grid-column-gap: 6px;
    grid-row-gap: 0px;
  }
}


@media (min-width: 480px) and (max-width: 767px) {
  .runner_value_item {
    flex: 0 0 calc(25% - 12px);
  }
  .jodi_box {
    flex: 0 0 calc(33.33% - 10px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .runner_value_item {
    font-size: 16px;
  }

  .runner_value_item {
    flex: 0 0 calc(16.5% - 11.5px);
  }

  .runner_value_item span {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .runner_value_item {
    font-size: 20px;
  }

  .runner_value_item {
    flex: 0 0 calc(11.5% - 4px);
  }

  .runner_value_item span {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1349px) {
  .runner_value_item {
    font-size: 22px;
  }

  .runner_value_item span {
    font-size: 18px;
  }
}

@media (min-width: 1350px) and (max-width: 1920px) {
  .runner_value_item span {
    font-size: 18px;
  }

  .runner_value_item {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .matka_top_input_box .floating_input_box {
    width: 87%;
  }
  
  .matka_top_input_box {
    height: 80%;
    width: 117%;
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
  }
}