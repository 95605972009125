table.mainMatka_table {
    width: 100%;
}

.anv-margTop-2 {
    margin-top: 1.6em;
}

table.mainMatka_table thead th {
    background-color: #4a4189;
    color: #fff;
    border: 1px solid #4a4189;
    padding-bottom: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-left: 2%;
}

table.mainMatka_table tbody tr td {
    border: 1px solid green;
    width: 200px;
    text-align: center;
    margin: 0;
    line-height: 20px;
    font-size: 20px;
    padding: 2%;
}

table.mainMatka_table tbody tr td:first-child {
    font-weight: 600;
}

.chrtChar_bx {
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-family: "Monospace";
    font-style: italic;
    font-weight: bold;
    -webkit-box-shadow: 0 2px 2px -2px #0E1119;
    -moz-box-shadow: 0 2px 2px -2px #0E1119;
    box-shadow: 0 2px 2px -2px #0E1119;
}

.chrtChar_row {
    display: table-row;
}

.chrtChar_innrFx {
    display: table-cell;
    border: 1px solid green;
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 24px;
    font-size: 20px;
    color: #17202A;
}

.middle-cht {
    color: #E74C3C;
}