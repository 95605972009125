/* Input style */
.floating_input_box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0;
  width: 100%;
}

.floating_input_box:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #0a53e4;
}

.floating_input_box .filled {
  transform: translate(0, 12px) scale(0.8);
}

.floating_input_box label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #3a4b6c;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.floating_input_box input {
  height: 64px;
  border-radius: 4px;
  padding: 26px 10px 10px 15px;
  line-height: 1;
  background-color: #edeff4;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid rgb(128 129 136);
  font-weight: 700;
  width: 100%;
  outline: none;
}

.runner_value_item input:focus {
  box-shadow: 0 5px 11px 0 rgb(33 37 41 / 15%);
}

.input_error_box {
  color: red;
  height: auto;
  padding: 0 !important;
  position: absolute;
  bottom: -16px;
  font-size: 12px;
}

.cptcha_input_error {
  margin-left: -100px;
}

.acc_statementTable {
  background: #fff;
}

.acc_statementTable table.table {
  margin: 0;
}

.acc_statementTable table.table th {
  color: #fff;
}

.accStat_contain .pagination a {
  color: #4a4189 !important;
}

.acc_statementTable {
  overflow: auto;
}

.box_contain {
  background: #e0e6e6;
  border: 1px solid #c8ced3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.box_contain > h4{
  margin: 19px 15px 0px;
}

@media (max-width:479px) {

  .acc_statementTable table.table {
    width: 950px;
    table-layout: auto;
  }
}

@media (min-width:480px) and (max-width:767px) {
  .acc_statementTable table.table {
    width: 1050px;
    table-layout: auto;
  }
}