.tvcontainer {
  position: fixed;
  bottom: 6%;
  right: 6%;

}

.tvicon {
  width: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow .6s;
  border: px solid rgb(121, 24, 185);
}

.tvicon:hover {
  box-shadow: 0 0 1px rgb(121, 24, 185);
}

.closeicon {
  position: absolute;
  top: -1%;
  right: 13px;
  /* background-color:#000; */
  cursor: pointer;
  text-align: center;



}

.embed-responsive-16by9 {
  padding-bottom: 25% !important;
}

.closeicon img {
  cursor: pointer;
  text-align: center;
  padding: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;


}

.mob_view {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .mob_view .video_modalNew {
    position: relative;
    bottom: 0;
    right: 0;
    background: #fff;
  }

}

.video_modalNew {
  position: fixed;
  bottom: 0;
  right: 0;
}

.ReactModal__Content--after-open {
  padding: 0px !important;
}

.success_bg {
  background-color: #00FF00;
}

.error_bg {
  background-color: #FF0000;
}

.match-index-row .in-play-row-right,
.row.matchBox-Row .in-play-row-left,
#refresh2 {
  z-index: -10;
}

@media (min-width: 300px) and (max-width: 567px) {

  /* .embed-responsive-16by9 {
    padding-bottom: 60% !important;
  } */
  .tvifram {
    width: 100%;
    min-height: 250px !important;

  }
}

.tviframe {
  min-height: 250px !important;
}

@media (min-width: 568px) and (max-width: 767px) {

  /* .embed-responsive-16by9 {
    padding-bottom: 40% !important;
  } */
  .tvifram {
    width: 100%;
    min-height: 250px !important;

  }
}

.parent-container {
  position: relative;

}

.overlay-container {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #33333380 !important;
}

/* #overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
} */

.text-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay-data {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

.text-red {
  color: #e84343 !important;
}

.min-max {
  background-color: var(--body-color-primary) !important;
  float: left;
}

.curentRunrate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

span.massage {
  width: 50%;
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

span.runrate {
  width: 50%;
  display: flex;
  justify-content: start;

}



@media (max-width:393px) {
  span.massage {
    width: 50%;
    display: flex;
    justify-content: start;
  }

  span.runrate {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-right: 14px;

  }
}

@media (min-width: 394px) and (max-width: 479px) {
  .score_widget_content_wrapper {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .score_widget_content_wrapper div {
    width: 100% !important;
    padding: 0;
    margin: 5px 0;
  }

  .score_widget_content_wrapper .bet_status_box {
    width: 100% !important;
  }

  .score_widget_content_wrapper .bet-status-text-p {
    word-spacing: 10px !important;
    padding: 10px 0px !important;
  }

  span.massage {
    width: 50%;
    display: flex;
    justify-content: start;
  }

  span.runrate {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-right: 14px;

  }

}


@media (min-width: 480px) and (max-width: 767px) {}